// App configuration

// Constants
const API_STAGE = 'prod'; // AWS Services API stage

export default {

    ApiStage : API_STAGE, // AWS Services API stage

    HistoryS3Bucket: `pip-history-bucket-${API_STAGE}`, // S3 bucket for history
    ProjectsS3Bucket: `pip-projects-bucket-${API_STAGE}`, // S3 bucket for projects

    // WebSocket parameters
    WebSocketURL: `wss://ws-api-${API_STAGE}.proinspector.ainspect.io`, // WebSocket URL
    WebSocketOpenRetryPeriod: 7000, // Period (miliseconds) between open retry 

    WebRTCPingPeriod: 2 * 1000, // Web RTC Ping period in ms

    HttpAPIRoot: `https://api-${API_STAGE}.proinspector.ainspect.io`, // HTTP API root

    WebSocketMultiPartMsgTimeout: 5 * 1000, // Timeout (miliseconds) for multi-part messages


    HistImgsBucketPrefix: (customer, projectId) => { return `cst/${customer}/prj/${projectId}/img` }, // S3 bucket prefix for history images
    DstImgsBucketPrefix: (customer, projectId, datasetId) => { return `cst/${customer}/prj/${projectId}/dst/${datasetId}/img` }, // S3 bucket prefix for Dataset images

    WebRTCCloseDelaySeconds: 5 * 60, // Seconds to delay close WebRTC after closing a page

    NumParallelRequests: 100, // Max number of parallel fetch requests
    MaxRequestRetry: 10, // Max number of API call request retries


    // Supported Camera Resolutions x FPS
    // Format can be 'MJPG' or 'YUYV'
    // supportedBoxCamCaptures, supportedBarCamCaptures must by sync:
    // Edge Dev Machine:
    // - /home/printai/pip_app/code/WebServer/static/js/config.js
    // - /home/printai/pip_app/code/Config/Camera.py
    // Backend Dev Machine:
    // - /home/printai/code/pip_fe_aws/pip-portal-app/src/config.js 
    supportedBoxCamCaptures: {    
        // '3264x2464@21FPS' :  {'Width': 3264,   'Height': 2464,   'FPS': 21,     'Format': 'RGB',     'Name': '3264 x 2464 @ 21 FPS'},
        // '3264x2464@10FPS' :  {'Width': 3264,   'Height': 2464,   'FPS': 10,     'Format': 'RGB',     'Name': '3264 x 2464 @ 10 FPS'},
        // '3264x1848@28FPS' :  {'Width': 3264,   'Height': 1848,   'FPS': 28,     'Format': 'RGB',     'Name': '3264 x 1848 @ 28 FPS'}, 
        // '3264x1848@14FPS' :  {'Width': 3264,   'Height': 1848,   'FPS': 14,     'Format': 'RGB',     'Name': '3264 x 1848 @ 14 FPS'}, 
        // '3264x1848@7FPS' :  {'Width': 3264,   'Height': 1848,   'FPS': 7,     'Format': 'RGB',     'Name': '3264 x 1848 @ 7 FPS'},    
        // '1920x1080@15FPS' :  {'Width': 1920,   'Height': 1080,   'FPS': 15,     'Format': 'RGB',     'Name': '1920 x 1080 @ 15 FPS'},
        // '1920x1080@30FPS' :  {'Width': 1920,   'Height': 1080,   'FPS': 30,     'Format': 'RGB',     'Name': '1920 x 1080 @ 30 FPS'},
        // '1640x1232@30FPS' :  {'Width': 1640,   'Height': 1232,   'FPS': 30,     'Format': 'RGB',     'Name': '1640 x 1232 @ 30 FPS'},
        // '1640x1232@15FPS' :  {'Width': 1640,   'Height': 1232,   'FPS': 15,     'Format': 'RGB',     'Name': '1640 x 1232 @ 15 FPS'},
        // '1280x720@60FPS' :  {'Width': 1280,   'Height':  720,   'FPS': 60,     'Format': 'RGB',     'Name': '1280 x  720 @ 60 FPS'}, 
        // '1280x720@30FPS' :  {'Width': 1280,   'Height':  720,   'FPS': 30,     'Format': 'RGB',     'Name': '1280 x  720 @ 30 FPS'},    
        '1920x1200@120FPS' :  {'Width': 1920,   'Height': 1200,   'FPS': 120,     'Format': 'RGB',     'Name': '1920 x 1200 @ 120 FPS'},
        '1920x1080@120FPS' :  {'Width': 1920,   'Height': 1080,   'FPS': 120,     'Format': 'RGB',     'Name': '1920 x 1080 @ 120 FPS'},
    },    
    supportedBarCamCaptures: {
        '3264x2448@5FPS' :  {'Width': 3264,   'Height': 2448,   'FPS': 5,     'Format': 'MJPG',     'Name': '3264 x 2448 @ 5 FPS'},
        '2592x1944@20FPS' : {'Width': 2592,   'Height': 1944,   'FPS': 20,    'Format': 'MJPG',     'Name': '2592 x 1944 @ 20 FPS'},
        '2592x1944@15FPS' : {'Width': 2592,   'Height': 1944,   'FPS': 15,    'Format': 'MJPG',     'Name': '2592 x 1944 @ 15 FPS'},
        '2592x1944@10FPS' : {'Width': 2592,   'Height': 1944,   'FPS': 10,    'Format': 'MJPG',     'Name': '2592 x 1944 @ 10 FPS'},
        '2592x1944@5FPS' :  {'Width': 2592,   'Height': 1944,   'FPS': 5,     'Format': 'MJPG',     'Name': '2592 x 1944 @ 5 FPS'},
        '2048x1536@20FPS' : {'Width': 2048,   'Height': 1536,   'FPS': 20,    'Format': 'MJPG',     'Name': '2048 x 1536 @ 20 FPS'},
        '2048x1536@15FPS' : {'Width': 2048,   'Height': 1536,   'FPS': 15,    'Format': 'MJPG',     'Name': '2048 x 1536 @ 15 FPS'},
        '2048x1536@10FPS' : {'Width': 2048,   'Height': 1536,   'FPS': 10,    'Format': 'MJPG',     'Name': '2048 x 1536 @ 10 FPS'},
        '2048x1536@5FPS' :  {'Width': 2048,   'Height': 1536,   'FPS': 5,     'Format': 'MJPG',     'Name': '2048 x 1536 @ 5 FPS'},
        '1920x1080@30FPS' : {'Width': 1920,   'Height': 1080,   'FPS': 30,    'Format': 'MJPG',     'Name': '1920 x 1080 @ 30 FPS'},
        '1280x1024@30FPS' : {'Width': 1280,   'Height': 1024,   'FPS': 30,    'Format': 'MJPG',     'Name': '1280 x 1024 @ 30 FPS'},
        '800x600@30FPS' :   {'Width': 800,    'Height': 600,    'FPS': 30,    'Format': 'MJPG',     'Name': '800 x 600 @ 30 FPS'},
        '640x480@30FPS' :   {'Width': 640,    'Height': 480,    'FPS': 30,    'Format': 'MJPG',     'Name': '640 x 480 @ 30 FPS'},
    },

    // https://pytorch.org/vision/stable/models.html#classification
    possibleBaseModels: {

        // SqueezeNet family
        'SqueezeNet1_0': {'gui_name': 'SqueezeNet 1.0', 'framework': 'PyTorch', 'pt_name': 'squeezenet1_0', 'num_params': 1248424},
        // 'SqueezeNet1_1': {'gui_name': 'SqueezeNet 1.1', 'framework': 'PyTorch', 'pt_name': 'squeezenet1_1', 'num_params': 1235496}, // Worse than 1.0
        // MobileNet family
        'MobileNetV2': {'gui_name': 'MobileNet V2', 'framework': 'PyTorch-Timm', 'pt_name': 'mobilenetv2_100.ra_in1k', 'num_params': 3.5e6},
        'MobileNetV3Small': {'gui_name': 'MobileNet V3 Small', 'framework': 'PyTorch-Timm', 'pt_name': 'mobilenetv3_small_100.lamb_in1k', 'num_params': 2.54e6},
        'MobileNetV3Large': {'gui_name': 'MobileNet V3 Large', 'framework': 'PyTorch-Timm', 'pt_name': 'mobilenetv3_large_100.ra_in1k', 'num_params': 5.48e6},
        // Resnet family
        'ResNet18': {'gui_name': 'ResNet 18', 'framework': 'PyTorch-Timm', 'pt_name': 'resnet18.fb_swsl_ig1b_ft_in1k', 'num_params': 11.69e6},
        // 'ResNet34': {'gui_name': 'ResNet 34', 'framework': 'PyTorch-Timm', 'pt_name': 'resnet34.a1_in1k', 'num_params': 21.8e6}, // Not good
        'ResNet50': {'gui_name': 'ResNet 50', 'framework': 'PyTorch-Timm', 'pt_name': 'resnet50.b2k_in1k', 'num_params': 25.56e6},
        'ResNet101': {'gui_name': 'ResNet 101', 'framework': 'PyTorch-Timm', 'pt_name': 'resnet101.tv2_in1k', 'num_params': 44.55e6},
        // 'ResNet152': {'gui_name': 'ResNet 152', 'framework': 'PyTorch-Timm', 'pt_name': 'resnet152.a1_in1k', 'num_params': 60.19e6}, // Not good
        // DenseNet family
        'DenseNet121': {'gui_name': 'DenseNet 121', 'framework': 'PyTorch-Timm', 'pt_name': 'densenet121.tv_in1k', 'num_params': 7.98e6},
        'DenseNet169': {'gui_name': 'DenseNet 169', 'framework': 'PyTorch-Timm', 'pt_name': 'densenet169.tv_in1k', 'num_params': 14.15e6},
        'DenseNet201': {'gui_name': 'DenseNet 201', 'framework': 'PyTorch-Timm', 'pt_name': 'densenet201.tv_in1k', 'num_params': 20.01e6},
        'DenseNet161': {'gui_name': 'DenseNet 161', 'framework': 'PyTorch-Timm', 'pt_name': 'densenet161.tv_in1k', 'num_params': 28.68e6},
        // Inception family
        // 'InceptionV3': {'gui_name': 'Inception V3', 'framework': 'PyTorch-Timm', 'pt_name': 'inception_v3.gluon_in1k', 'num_params': 23.83e6}, // It fails
        // 'InceptionV4': {'gui_name': 'Inception V4', 'framework': 'PyTorch-Timm', 'pt_name': 'inception_v4.tf_in1k', 'num_params': 42.68e6}, // It fails
        // EfficientNet family
        'EfficientNetB0': {'gui_name': 'EfficientNet B0', 'framework': 'PyTorch-Timm', 'pt_name': 'efficientnet_b0.ra_in1k', 'num_params': 5.3e6},
        'EfficientNetB1': {'gui_name': 'EfficientNet B1', 'framework': 'PyTorch-Timm', 'pt_name': 'efficientnet_b1.ft_in1k', 'num_params': 7.8e6},
        'EfficientNetB2': {'gui_name': 'EfficientNet B2', 'framework': 'PyTorch-Timm', 'pt_name': 'efficientnet_b2.ra_in1k', 'num_params': 9.11e6},
        // 'EfficientNetB3': {'gui_name': 'EfficientNet B3', 'framework': 'PyTorch-Timm', 'pt_name': 'efficientnet_b3.ra2_in1k', 'num_params': 12.2e6},  // Bad performance
        // 'EfficientNetB4': {'gui_name': 'EfficientNet B4', 'framework': 'PyTorch-Timm', 'pt_name': 'efficientnet_b4.ra2_in1k', 'num_params': 19.3e6},   // Bad performance
        // 'EfficientNetB5': {'gui_name': 'EfficientNet B5', 'framework': 'PyTorch-Timm', 'pt_name': 'efficientnet_b5.sw_in12k_ft_in1k', 'num_params': 30.4e6}, // Does not converge
        // VGG family
        // 'VGG11': {'gui_name': 'VGG 11', 'framework': 'PyTorch-Timm', 'pt_name': 'vgg11.tv_in1k', 'num_params': 132.86e6}, // Not good
        // 'VGG13': {'gui_name': 'VGG 13', 'framework': 'PyTorch-Timm', 'pt_name': 'vgg13.tv_in1k', 'num_params': 133.05e6}, // Not good
        // 'VGG16': {'gui_name': 'VGG 16', 'framework': 'PyTorch-Timm', 'pt_name': 'vgg16.tv_in1k', 'num_params': 138.36e6}, // Not good
        // 'VGG19': {'gui_name': 'VGG 19', 'framework': 'PyTorch-Timm', 'pt_name': 'vgg19.tv_in1k', 'num_params': 143.67e6}, // Not good
        // Xception family
        // 'Xception41': {'gui_name': 'Xception 41', 'framework': 'PyTorch-Timm', 'pt_name': 'xception41.tf_in1k', 'num_params': 20.97e6}, // worse than 41p
        'Xception41p': {'gui_name': 'Xception 41+', 'framework': 'PyTorch-Timm', 'pt_name': 'xception41p.ra3_in1k', 'num_params': 26.91e6},
        // 'Xception65': {'gui_name': 'Xception 65', 'framework': 'PyTorch-Timm', 'pt_name': 'xception65.ra3_in1k', 'num_params': 39.92e6}, // worse than 65p
        'Xception65p': {'gui_name': 'Xception 65+', 'framework': 'PyTorch-Timm', 'pt_name': 'xception65p.ra3_in1k', 'num_params': 39.82e6},
        'Xception71': {'gui_name': 'Xception 71', 'framework': 'PyTorch-Timm', 'pt_name': 'xception71.tf_in1k', 'num_params': 42.34e6},
        // ConvNext family
        'ConvNexxtAtto': {'gui_name': 'ConvNext Atto', 'framework': 'PyTorch-Timm', 'pt_name': 'convnext_atto.d2_in1k', 'num_params': 3.7e6},
        'ConvNexxtFemto': {'gui_name': 'ConvNext Femto', 'framework': 'PyTorch-Timm', 'pt_name': 'convnext_femto.d1_in1k', 'num_params': 5.22e6},
        'ConvNexxtPico': {'gui_name': 'ConvNext Pico', 'framework': 'PyTorch-Timm', 'pt_name': 'convnext_pico.d1_in1k', 'num_params': 9.05e6},
        'ConvNexxtNano': {'gui_name': 'ConvNext Nano', 'framework': 'PyTorch-Timm', 'pt_name': 'convnext_nano.in12k_ft_in1k', 'num_params': 15.59e6},
        // 'ConvNexxtTiny': {'gui_name': 'ConvNext Tiny', 'framework': 'PyTorch-Timm', 'pt_name': 'convnext_tiny.in12k_ft_in1k_384', 'num_params': 28.59e6}, // Too Big
        // 'ConvNexxtSmall': {'gui_name': 'ConvNext Small', 'framework': 'PyTorch-Timm', 'pt_name': 'convnext_small.in12k_ft_in1k_384', 'num_params': 50.22e6},  // Too Big



        // Remove once it does not exist anymore
        // 'MovileNetV3Small': {'gui_name': 'MobileNet V3 Small old', 'framework': 'PyTorch', 'pt_name': 'mobilenet_v3_small', 'num_params': 2542856},


        




        
        // 'MobileNetV3Small': {'gui_name': 'MobileNet V3 Small', 'framework': 'PyTorch', 'pt_name': 'mobilenet_v3_small', 'num_params': 2542856},

        


        // 'ResNet18 Timm': {'gui_name': 'ResNet 18 Timm', 'framework': 'PyTorch-Timm', 'pt_name': 'resnet18', 'num_params': 11689512},



        // 'MobileNetV2': {'gui_name': 'MobileNet V2', 'framework': 'PyTorch', 'pt_name': 'mobilenet_v2', 'num_params': 3504872},
        // 'ConvNextAtto': {'gui_name': 'ConvNext Atto', 'framework': 'PyTorch-Timm', 'pt_name': 'convnext_atto', 'num_params': 3.4e6},

        // 'ConvNextFemto': {'gui_name': 'ConvNext Femto', 'framework': 'PyTorch-Timm', 'pt_name': 'convnext_femto', 'num_params': 3.7e6},
        // 'ConvNextPico': {'gui_name': 'ConvNext Pico', 'framework': 'PyTorch-Timm', 'pt_name': 'convnext_pico', 'num_params': 8.5e6},
        // 'ConvNextNano': {'gui_name': 'ConvNext Nano', 'framework': 'PyTorch-Timm', 'pt_name': 'convnext_nano', 'num_params': 3.7e6},




        // 'MobileNetV3Large': {'gui_name': 'MobileNet V3 Large', 'framework': 'PyTorch', 'pt_name': 'mobilenet_v3_large', 'num_params': 5483032},
        // 'DenseNet121': {'gui_name': 'DenseNet 121', 'framework': 'PyTorch', 'pt_name': 'densenet121', 'num_params': 7978856},
        // 'ResNet18': {'gui_name': 'ResNet 18', 'framework': 'PyTorch', 'pt_name': 'resnet18', 'num_params': 11689512},
        // 'GoogLeNet': {'gui_name': 'GoogLeNet', 'framework': 'PyTorch', 'pt_name': 'googlenet', 'num_params': 13004888},
        // 'DenseNet169': {'gui_name': 'DenseNet 169', 'framework': 'PyTorch', 'pt_name': 'densenet169', 'num_params': 14149480},
        // 'DenseNet201': {'gui_name': 'DenseNet 201', 'framework': 'PyTorch', 'pt_name': 'densenet201', 'num_params': 20013928},
        // 'ResNet34': {'gui_name': 'ResNet 34', 'framework': 'PyTorch', 'pt_name': 'resnet34', 'num_params': 21797672},
        // 'ResNet50': {'gui_name': 'ResNet 50', 'framework': 'PyTorch', 'pt_name': 'resnet50', 'num_params': 25557032},
        // 'InceptionV3': {'gui_name': 'Inception V3', 'framework': 'PyTorch', 'pt_name': 'inception_v3', 'num_params': 27161264},
        // 'ConvNextTiny': {'gui_name': 'ConvNext Tiny', 'framework': 'PyTorch', 'pt_name': 'convnext_tiny', 'num_params': 28589128},
        // 'DenseNet161': {'gui_name': 'DenseNet 161', 'framework': 'PyTorch', 'pt_name': 'densenet161', 'num_params': 28681000},
        // 'ResNet101': {'gui_name': 'ResNet 101', 'framework': 'PyTorch', 'pt_name': 'resnet101', 'num_params': 44549160},
        // 'ConvNextSmall': {'gui_name': 'ConvNext Small', 'framework': 'PyTorch', 'pt_name': 'convnext_small', 'num_params': 50223688},
        // 'ResNet152': {'gui_name': 'ResNet 152', 'framework': 'PyTorch', 'pt_name': 'resnet152', 'num_params': 60192808},
        // 'AlexNet': {'gui_name': 'AlexNet', 'framework': 'PyTorch', 'pt_name': 'alexnet', 'num_params': 61100840},
        // 'ConvNextBase': {'gui_name': 'ConvNext Base', 'framework': 'PyTorch', 'pt_name': 'convnext_base', 'num_params': 88591464},
        // 'VGG11': {'gui_name': 'VGG 11', 'framework': 'PyTorch', 'pt_name': 'vgg11', 'num_params': 132863336},
        // 'VGG11_bn': {'gui_name': 'VGG 11 (BatchNorm)', 'framework': 'PyTorch', 'pt_name': 'vgg11_bn', 'num_params': 132868840},
        // 'VGG13': {'gui_name': 'VGG 13', 'framework': 'PyTorch', 'pt_name': 'vgg13', 'num_params': 133047848},
        // 'VGG13_bn': {'gui_name': 'VGG 13 (BatchNorm)', 'framework': 'PyTorch', 'pt_name': 'vgg13_bn', 'num_params': 133053736},
        // 'VGG16': {'gui_name': 'VGG 16', 'framework': 'PyTorch', 'pt_name': 'vgg16', 'num_params': 138357544},
        // 'VGG16_bn': {'gui_name': 'VGG 16 (BatchNorm)', 'framework': 'PyTorch', 'pt_name': 'vgg16_bn', 'num_params': 138365992},
        // 'VGG19': {'gui_name': 'VGG 19', 'framework': 'PyTorch', 'pt_name': 'vgg19', 'num_params': 143667240},
        // 'VGG19_bn': {'gui_name': 'VGG 19 (BatchNorm)', 'framework': 'PyTorch', 'pt_name': 'vgg19_bn', 'num_params': 143678248},
        // 'ConvNextLarge': {'gui_name': 'ConvNext Large', 'framework': 'PyTorch', 'pt_name': 'convnext_large', 'num_params': 197767336},



        // 'Xception41': {'gui_name': 'Xception 41', 'framework': 'PyTorch-Timm', 'pt_name': 'xception41', 'num_params': 20861480},
        // 'Xception41p': {'gui_name': 'Xception 41+', 'framework': 'PyTorch-Timm', 'pt_name': 'xception41p', 'num_params': 20861480},
        // 'Xception65': {'gui_name': 'Xception 65', 'framework': 'PyTorch-Timm', 'pt_name': 'xception65', 'num_params': 20861480},
        // 'Xception65p': {'gui_name': 'Xception 65+', 'framework': 'PyTorch-Timm', 'pt_name': 'xception65p', 'num_params': 20861480},
        // 'Xception71': {'gui_name': 'Xception 71', 'framework': 'PyTorch-Timm', 'pt_name': 'xception71', 'num_params': 20861480},

    },
}
